import React, { useState,useEffect} from "react";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { InstantCreate, ClickButton } from "./ClickButton";
import { BiPlus } from "react-icons/bi";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

const TextInputForm = ({
  name,
  type,
  suffix_icon,
  prefix_icon,
  labelname,
  value,
  onChange,
  readOnly,
  placeholder,
  onKeyDown,
  autoFocus,
  disabled,
  onKeyPress,
}) => {
  return (
    <>
      <div className="pb-2">{labelname ? <label>{labelname}</label> : ""}</div>
      <div className="form-icon">
        <Form.Group className="">
          {prefix_icon ? (
            <span className="prefix-icon">{prefix_icon}</span>
          ) : (
            ""
          )}
          <input
            type={type}
            name={name}
            className={`form-cntrl w-100 
                      ${prefix_icon && suffix_icon
                ? "form-control-padboth"
                : prefix_icon
                  ? "form-control-padleft"
                  : suffix_icon
                    ? "form-control-padright"
                    : ""
              }`}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
            disabled={disabled}
            onKeyPress={onKeyPress}
            readOnly={readOnly}
          />
          {suffix_icon ? (
            <span className="suffix-icon">{suffix_icon}</span>
          ) : (
            ""
          )}
        </Form.Group>
      </div>
    </>
  );
};
const TextForm = ({
  name,
  type,
  suffix_icon,
  prefix_icon,
  labelname,
  value,
  onChange,
  readOnly,
  placeholder,
  onKeyDown,
  autoFocus,
  disabled,
  onKeyPress,
}) => {
  return (
    <>
      <div className="pb-2">{labelname ? <label>{labelname}</label> : ""}</div>
      <div className="form-icon">
        <Form.Group className="">
          {prefix_icon ? (
            <span className="prefix-icon">{prefix_icon}</span>
          ) : (
            ""
          )}
          <input
            type={type}
            name={name}
            className={`form-cntrlogin w-100 
                      ${prefix_icon && suffix_icon
                ? "form-control-padboth"
                : prefix_icon
                  ? "form-control-padleft"
                  : suffix_icon
                    ? "form-control-padright"
                    : ""
              }`}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
            disabled={disabled}
            onKeyPress={onKeyPress}
            readOnly={readOnly}
          />
          {suffix_icon ? (
            <span className="suffix-icon">{suffix_icon}</span>
          ) : (
            ""
          )}
        </Form.Group>
      </div>
    </>
  );
};
const DropDown = ({
  placeholder,
  optionlist,
  labelname,
  modeltitle = "create",
  value,
  onChange,
  name,
  onClick,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    if (selectedValue !== value) {
      onChange({
        ...value,
        [name]: selectedValue,
      });
    }
  };

  // Find the selected option based on the current value
  const selectedOption = optionlist.find((option) => option.value === value);
  console.log("selectedOption", selectedOption);
  console.log("optionlist", optionlist);
  console.log("value", value);
  return (
    <>
      <div className="pb-2 px-3">
        {labelname ? <label>{labelname}</label> : ""}
      </div>
      <div className="w-100 d-flex">
        <Select
          placeholder={placeholder}
          options={optionlist}
          labelField="title"
          valueField="value"
          value={selectedOption}
          multi
          className="w-100"
          onChange={handleChange}
          onClick={onClick}
        ></Select>
        <InstantCreate
          label={<BiPlus />}
          className="instant-add"
          onClick={onClick}
        ></InstantCreate>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header>
            <Modal.Title>
              {modeltitle} {labelname ? labelname : "Model"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextInputForm placeholder={placeholder} labelname={labelname} />
          </Modal.Body>
          <Modal.Footer>
            <ClickButton label={<>Cancel</>} onClick={handleCloseModal}>
              Close
            </ClickButton>
            <ClickButton label={<>Submit</>} onClick={handleCloseModal}>
              Save Changes
            </ClickButton>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
const DropDownUI = ({
  optionlist = [],
  className,
  name,
  labelname,
  placeholder,
  value,
  onChange,
  onKeyDown,
  autoFocus,
  disabled,
  isMulti,
}) => {
  const handleChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    if (selectedValue !== value) {
      onChange({
        ...value,
        [name]: selectedValue,
      });
    }
  };

  // Find the selected option based on the current value
  const selectedOption = optionlist.find((option) => option.value === value);
  return (
    <>
      <div className="pb-2">{labelname ? <label>{labelname}</label> : ""}</div>

      <div className="w-100 d-flex">
        <Select
          placeholder={placeholder}
          options={optionlist}
          labelField="label"
          valueField="value"
          value={selectedOption} // Set the selected option
          onChange={handleChange}
          isMulti={isMulti}
          className="w-100"
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      </div>
    </>
  );
};
// const Calender = ({ setLabel, selectedDate, calenderlabel }) => {
//   const [startDate, setStartDate] = useState(
//     selectedDate != null ? selectedDate : new Date()
//   );

//   return (
//     <>
//       <div className="pb-2 px-3">
//         <label>{calenderlabel}</label>
//       </div>
//       <DatePicker
//         selected={startDate}
//         onChange={(date) => {
//           setStartDate(date);
//           setLabel(date, "date_of_birth");
//         }}
//         dateFormat="dd-MM-yyyy"
//         className="w-100 form-cntrl"
//         selectsStart
//         startDate={startDate}
//       />
//     </>
//   );
// };

const Calender = ({ setLabel, selectedDate, calenderlabel, placeholder }) => {
  const [startDate, setStartDate] = useState(selectedDate ? new Date(selectedDate) : null);

  // Sync `startDate` with `selectedDate` whenever `selectedDate` changes
  useEffect(() => {
    setStartDate(selectedDate ? new Date(selectedDate) : null);
  }, [selectedDate]);

  return (
    <>
      <div className='pb-2 px-3'>
        <label>{calenderlabel}</label>
      </div>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          setLabel(date, 'date_of_birth'); // Pass 'date_of_birth' as the field name
        }}
        dateFormat='dd-MM-yyyy'
        className="w-100 form-cntrl"
        selectsStart
        startDate={startDate}
        placeholderText={placeholder}
      />
    </>
  );
};
const Time = ({ labelname }) => {
  const [time, setTime] = useState("00:00");
  return (
    <div>
      <div className="pb-2 px-3">
        {labelname ? <label>{labelname}</label> : ""}
      </div>
      <TimePicker
        onChange={setTime}
        value={time}
        disableClock={true}
        format="HH:mm"
        hourPlaceholder="HH"
        minutePlaceholder="MM"
        className="form-cntrl w-100"
      />
    </div>
  );
};
export { TextInputForm, DropDown, Calender, DropDownUI, Time, TextForm };
