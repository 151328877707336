import React, { useState } from 'react'
import { Route, Routes, NavLink } from 'react-router-dom';
import DashBoard from '../../pages/DashBoard';
import User from '../../pages/User'
import UserCreation from '../../pages/UserCreation';
import Company from '../../pages/Company'
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { LuDot } from "react-icons/lu";
import { FaSquarePlus } from "react-icons/fa6";
import { Button, Collapse } from 'react-bootstrap';
import ContractType from '../../pages/ContractType';
import ContractTypeCreation from '../../pages/ContractTypeCreation';
import Site from '../../pages/Site';
import SiteCreation from '../../pages/SiteCreation';
import Location from '../../pages/Location';
import LocationCreation from '../../pages/LocationCreation';
import Model from '../../pages/Model';
import ModelCreation from '../../pages/ModelCreation';
import Customer from '../../pages/Customer';
import CustomerCreation from '../../pages/CustomerCreation';
import Turbine from '../../pages/Turbine';
import TurbineCreation from '../../pages/TurbineCreation';
import Error from '../../pages/Error'
import { MdOutlineDashboard, MdOutlinePerson, MdOutlineStorefront } from "react-icons/md";
import DailyGeneration from '../../pages/DailyGeneration';
import DgCreation from '../../pages/DgCreation';
import { useNavigate } from 'react-router-dom';
import { BsBoxArrowRight } from "react-icons/bs";
import GridFault from '../../pages/GridFault';
import Maintenance from '../../pages/Maintenance';
import GridDrop from '../../pages/GridDrop';
import DgReport from '../../pages/DgReport';
import Role from '../../pages/Role';
import CustomerGroup from '../../pages/CustomerGroup';
import PdfUpload from '../../pages/PdfUpload';
import Gallery from '../../pages/Gallery';
import ErrorReport from '../../pages/ErrorReport';
const SideBar = ({ onLogout }) => {
  const [open, setOpen] = useState(null);
  const [isDeactive, SetDeactive] = useState(false);
  const toggle = () => { SetDeactive(!isDeactive ? 'remove' : ''); };
  const navigate = useNavigate();
  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };
  return (
    <>
      <div className={` ${isDeactive ? "wrap-remove" : ""}`} id="sidebar-wrapper">
        <div className="list-group regular">
          <ul>
            <li>
              <NavLink to="/dashboard" className="nav-link">
                <span className="list-icon"><MdOutlineDashboard /></span>
                <span class="list-text">Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/console/user" className="nav-link">
                <span className="list-icon"><MdOutlinePerson /></span>
                <span class="list-text">User</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/console/company" className="nav-link ">
                <span className="list-icon"><MdOutlineStorefront /></span>
                <span class="list-text">Company</span>
              </NavLink>
            </li>
            <li>
              <div className="nav-link sub-menu" onClick={() => setOpen(open === 1 ? null : 1)}>
                <span className="list-icon"><FaSquarePlus /></span>
                <span class="list-text"> Master</span>
                <span className="list-icon ps-3 arrow"><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={open === 1 ? true : false}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to="/console/master/role" className="nav-link">
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Role</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/contracttype" className="nav-link">
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Contract Type</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/site" className="nav-link">
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Site</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/location" className="nav-link ">
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Loctaion</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/model" className="nav-link ">
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Model</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/customergroup" className="nav-link ">
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Customer Group</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/customer" className="nav-link ">
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Customer</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/turbine" className="nav-link ">
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Turbine</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/error" className="nav-link ">
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Error</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/gridfault" className="nav-link ">
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Grid Fault</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/griddrop" className="nav-link ">
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Grid Drop</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/maintenance" className="nav-link ">
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Maintenance</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/gallery" className="nav-link ">
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Gallery</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <NavLink to="/console/dailygeneration" className="nav-link ">
                <span className="list-icon"><MdOutlineStorefront /></span>
                <span class="list-text">DailyGeneration</span>
              </NavLink>
            </li>
            <li>
              <div className="sub-menu nav-link" onClick={() => setOpen(open === 2 ? null : 2)}>
                <span className="list-icon"><FaSquarePlus /></span>
                <span class="list-text"> Reports</span>
                <span className="list-icon ps-3 arrow"><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={open === 2 ? true : false}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to="/console/report/dgr" className="nav-link" onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text"> DG Reports</span>
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/console/report/error" className="nav-link" onClick={window.innerWidth <= 768 ? toggle : null}>
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text"> Error Reports</span>
                    </NavLink>
                  </li> */}
                </ul>
              </Collapse>
            </li>
          </ul>
        </div>
        <div className='log-out'>
          <div className='list-group'>
            <ul>
              <li>
                <Button onClick={handleLogout}>
                  <span className="list-icon"><BsBoxArrowRight /></span>
                  <span className="list-text">Logout</span>
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* main-content start */}
      <div className="navbar navbar-expand  px-lg-4 header">
        <div className='d-lg-block d-none'>
          <Button className="menu-toggle" onClick={toggle} id="menu-toggle">
            <span class="navbar-toggler-icon"></span>
          </Button>
        </div>
        <div className='user-logo me-auto'>
          <img src={require('../sidebar/images/logo.png')} className='img-fluid logo' alt="" />
        </div>
        <div className='d-block d-lg-none ms-auto'>
          <Button className="menu-toggle" onClick={toggle} id="menu-toggle">
            <span class="navbar-toggler-icon"></span>
          </Button>
        </div>
        <div className='d-lg-block d-none ms-auto'>
          <Button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </Button>
        </div>
      </div>
      <div id="page-content-wrapper" className={`${isDeactive ? "page-remove" : ""}`}>
        <div className='content-bg'>
          <div className="px-lg-4 py-4">
            <div class="main-content">
              <Routes>
                <Route path='/dashboard' element={<DashBoard />}></Route>
                <Route path='/console/user' element={<User />}></Route>
                <Route path='/console/user/create' element={<UserCreation />}></Route>
                <Route path='/console/gallery' element={<Gallery/>}></Route>
                <Route path='/console/company' element={<Company />}></Route>
                <Route path='/console/master/role' element={<Role />}></Route>
                <Route path='/console/master/contracttype' element={<ContractType />}></Route>
                <Route path='/console/master/contracttype/create' element={<ContractTypeCreation />}></Route>
                <Route path='/console/master/site' element={<Site />}></Route>
                <Route path='/console/master/site/create' element={<SiteCreation />}></Route>
                <Route path='/console/master/location' element={<Location />}></Route>
                <Route path='/console/master/location/create' element={<LocationCreation />}></Route>
                <Route path='/console/master/model' element={<Model />}></Route>
                <Route path='/console/master/model/create' element={<ModelCreation />}></Route>
                <Route path='/console/master/customergroup' element={<CustomerGroup />}></Route>
                <Route path='/console/master/customer' element={<Customer />}></Route>
                <Route path='/console/master/customer/create' element={<CustomerCreation />}></Route>
                <Route path='/console/master/turbine' element={<Turbine />}></Route>
                <Route path='/console/master/turbine/create' element={<TurbineCreation />}></Route>
                <Route path='/console/master/error' element={<Error />}></Route>
                <Route path='/console/master/gridfault' element={<GridFault />}></Route>
                <Route path='/console/master/maintenance' element={<Maintenance />}></Route>
                <Route path='/console/master/griddrop' element={<GridDrop />}></Route>
                <Route path='/console/master/pdfupload' element={<PdfUpload />}></Route>
                <Route path='/console/dailygeneration' element={<DailyGeneration />}></Route>
                <Route path='/console/dailygeneration/create' element={<DgCreation />}></Route>
                <Route path='/console/report/dgr' element={<DgReport />}></Route>
                <Route path='/console/report/error' element={<ErrorReport />}></Route>
              </Routes>
            </div>
          </div>
        </div>
      </div>
      {/* main-content-end */}
    </>
  )
}

export default SideBar