import React, { useState } from "react";
import { Table, Button, Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Buttons } from "../Compnents/ClickButton";
import Spinner from "react-bootstrap/Spinner";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import { FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";
import moment from 'moment';
import Swal from 'sweetalert2';

const TableUI = ({
  headers,
  body,
  style,
  type,
  rowData,
  planViewAction,
  handleEditClick,
  onDelete,
  pageview,
  onSort,
  sortOrder,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  // Calculate the range of data to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = body.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(body.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleUserViewClick = (rowData) => {
    navigate("/console/user/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleUserEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleUserDeleteClick = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch("https://api.srivarugreenenergy.com/user/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: id,
            }),
          }
        );

        const responseData = await response.json();
        console.log("responseData", responseData);

        if (responseData.status === "Success") {
          Swal.fire({
            title: 'Deleted!',
            text: 'The user has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };

  const handleCompanyViewClick = (rowData) => {
    navigate("/console/company/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleCompanyEditClick = (rowData) => {
    handleEditClick(rowData);
  };

  const handleContractTypeViewClick = (rowData) => {
    navigate("/console/master/contracttype/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleRoleEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleRoleDeleteClick = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/role/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              role_id: id,
            }),
          }
        );

        const responseData = await response.json();

        console.log("responseData", responseData);

        if (responseData.status === "Success") {
          Swal.fire({
            title: 'Deleted!',
            text: 'The Role has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };
  const handleContractTypeEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleContractTypeDeleteClick = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/contract_type/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              contract_id: id,
            }),
          }
        );

        const responseData = await response.json();

        console.log("responseData", responseData);

        if (responseData.status === "Success") {
          Swal.fire({
            title: 'Deleted!',
            text: 'The Contract has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };

  const handleSiteViewClick = (rowData) => {
    navigate("/console/master/site/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleSiteEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleSiteDeleteClick = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/site/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              site_id: id,
            }),
          }
        );

        const responseData = await response.json();
        console.log("responseData", responseData);
        if (responseData.status === "Success") {
          Swal.fire({
            title: 'Deleted!',
            text: 'The Site has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };

  const handleLocationViewClick = (rowData) => {
    navigate("/console/master/location/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleLocationEditClick = (rowData) => {
    handleEditClick(rowData);
  };

  const handleLocationDeleteClick = async (id) => {
    try {
      setLoading(true);
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/location/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              location_id: id,
            }),
          }
        );

        const responseData = await response.json();
        console.log("responseData", responseData);

        setLoading(false);
        if (responseData.status === "Success") {
          Swal.fire({
            title: 'Deleted!',
            text: 'The Location has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };

  // const handleMagazineViewClick = (rowData) => {
  //     navigate("/console/master/magazine/create", { state: { type: "view", rowData: rowData } });
  // };

  const handleModelViewClick = (rowData) => {
    navigate("/console/master/model/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleModelEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleModelDeleteClick = async (id) => {
    try {
      setLoading(true);
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/model/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              model_id: id,
            }),
          }
        );

        const responseData = await response.json();
        console.log("responseData", responseData);

        setLoading(false);
        if (responseData.status === "Success") {
          Swal.fire({
            title: 'Deleted!',
            text: 'The Model has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };
  const handleCustomerGroupEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleCustomerGroupDeleteClick = async (id) => {
    try {
      setLoading(true);
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/customergroup/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              customergroup_uniq_id: id,
            }),
          }
        );

        const responseData = await response.json();
        console.log("responseData", responseData);

        setLoading(false);
        if (responseData.status === "Success") {
          Swal.fire({
            title: 'Deleted!',
            text: 'The CustomerGroup has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };
  const handleCustomerViewClick = (rowData) => {
    navigate("/console/master/customer/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleCustomerEditClick = (rowData) => {
    navigate("/console/master/customer/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleCustomerDeleteClick = async (id) => {
    try {
      setLoading(true);
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/customer/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              customer_unique_id: id,
            }),
          }
        );

        const responseData = await response.json();

        setLoading(false);
        if (responseData.status === "Success") {
          Swal.fire({
            title: 'Deleted!',
            text: 'The Customer has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };
  const handleTurbineViewClick = (rowData) => {
    navigate("/console/master/turbine/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleTurbineEditClick = (rowData) => {
    navigate("/console/master/turbine/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleTurbineDeleteClick = async (id) => {
    try {
      setLoading(true);
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/turbine/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              turbine_id: id,
            }),
          }
        );

        const responseData = await response.json();
        console.log("responseData", responseData);

        setLoading(false);
        if (responseData.status === "Success") {
          Swal.fire({
            title: 'Deleted!',
            text: 'The Turbine has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };
  const handleErrorViewClick = (rowData) => {
    navigate("/console/master/error/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleErrorEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleErrorDeleteClick = async (id) => {
    try {
      setLoading(true);
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/error/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              error_id: id,
            }),
          }
        );

        const responseData = await response.json();

        console.log("responseData", responseData);
        setLoading(false);
        if (responseData.status === "Success") {
          Swal.fire({
            title: 'Deleted!',
            text: 'The Error has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };
  const handleGridEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleGridDeleteClick = async (id) => {
    try {
      setLoading(true);
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/grid_fault/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              grid_fault_id: id,
            }),
          }
        );

        const responseData = await response.json();

        console.log("responseData", responseData);
        setLoading(false);
        if (responseData.status === "Success") {
          Swal.fire({
            title: 'Deleted!',
            text: 'The Grid has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };
  const handleGriddropEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleGriddropDeleteClick = async (id) => {
    try {
      setLoading(true);
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/grid_drop/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              grid_drop_id: id,
            }),
          }
        );

        const responseData = await response.json();

        console.log("responseData", responseData);
        setLoading(false);
        if (responseData.status === "Success") {
          Swal.fire({
            title: 'Deleted!',
            text: 'The Griddrop has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };
  const handleMaintenanceEditClick = (rowData) => {
    handleEditClick(rowData);
  };
  const handleMaintenanceDeleteClick = async (id) => {
    try {
      setLoading(true);
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/maintenance/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              maintenance_id: id,
            }),
          }
        );

        const responseData = await response.json();

        console.log("responseData", responseData);
        setLoading(false);
        if (responseData.status === "Success") {
          Swal.fire({
            title: 'Deleted!',
            text: 'The Maintenance has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };
  const handleDailygenViewClick = (rowData) => {
    navigate("/console/master/dailygeneration/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleDailygenEditClick = (rowData) => {
    navigate("/console/dailygeneration/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleDailygenDeleteClick = async (id) => {
    try {
      setLoading(true);
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'my-confirm-button-class',
          cancelButton: 'my-cancel-button-class',
        }
      });

      if (result.isConfirmed) {
        const response = await fetch(
          "https://api.srivarugreenenergy.com/daily_generation/delete.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              daily_generation_id: id,
            }),
          }
        );

        const responseData = await response.json();

        console.log("responseData", responseData);
        setLoading(false);
        if (responseData.status === 200) {
          Swal.fire({
            title: 'Deleted!',
            text: 'The Dailygen has been deleted.',
            icon: 'success',
            confirmButtonColor: '#ff5733',
            customClass: {
              confirmButton: 'my-confirm-button-class',
            }
          });
          onDelete();
        } else {
          Swal.fire(
            'Error!',
            'There was an issue deleting the user.',
            'error'
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire(
        'Error!',
        'An unexpected error occurred.',
        'error'
      );
    }
  };
  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    onSort(key, direction);
  };

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...currentItems];
    if (sortConfig.key !== "") {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [currentItems, sortConfig]);
  return (
    <>
      {pageview === "yes" && pageview !== undefined ? (
        <div className="text-end">
          <span className="mx-1">
            Page {currentPage} of {totalPages}
          </span>
          <span className="mx-1">
            <Buttons
              lable={
                <>
                  <MdChevronLeft className="icon-style" />
                </>
              }
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            />
          </span>
          <span className="mx-1">
            <Buttons
              lable={
                <>
                  <MdChevronRight className="icon-style" />
                </>
              }
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            />
          </span>
        </div>
      ) : (
        " "
      )}
      <Table responsive="md" style={style}>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>
                {header === "Customer ID" ||
                  header === "Customer Name" ||
                  header === "Customer Name" ||
                  header === "WTG NO" ||
                  header === "Error Code" ||
                  header === "Grid Fault Code" ||
                  header === "grid drop code" ||
                  header === "Maintenance Code" ? (
                  <div
                    onClick={() =>
                      requestSort(header.toLowerCase().replace(" ", "_"))
                    }
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {header}
                    {sortConfig.key ===
                      header.toLowerCase().replace(" ", "_") &&
                      sortConfig.direction === "asc" && (
                        <FaSortAlphaDown style={{ marginLeft: "8px" }} />
                      )}
                    {sortConfig.key ===
                      header.toLowerCase().replace(" ", "_") &&
                      sortConfig.direction === "desc" && (
                        <FaSortAlphaUp style={{ marginLeft: "8px" }} />
                      )}
                    {sortConfig.key !==
                      header.toLowerCase().replace(" ", "_") && (
                        <FaSortAlphaDown style={{ marginLeft: "8px" }} />
                      )}
                  </div>
                ) : (
                  header
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {type === "USER" && ( // Checking if type is "USER"
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.user_name}</td>
                  <td>{rowData.mobile_number}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleUserEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleUserDeleteClick(rowData.user_id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "company" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.company_name}</td>
                  <td>{rowData.mobile_number}</td>
                  <td>{rowData.city}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleCompanyEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
               {type === "iamge" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.image}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleRoleEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleRoleDeleteClick(rowData.role_id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "role" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.role_name}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleRoleEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleRoleDeleteClick(rowData.role_id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "contracttype" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.contract_name}</td>
                  <td>{rowData.contract_code}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleContractTypeEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleContractTypeDeleteClick(rowData.contract_id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "site" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.state_id}</td>
                  <td>{rowData.site_name}</td>
                  <td>{rowData.short_code}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleSiteEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleSiteDeleteClick(rowData.site_id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "location" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.state_id}</td>
                  {/* <td>{rowData.site_id}</td> */}
                  <td>{rowData.location_name}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleLocationEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleLocationDeleteClick(rowData.location_id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "model" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.model_type}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleModelEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleModelDeleteClick(rowData.model_id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "customergroup" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.customergroup_name}</td>
                  <td>{rowData.customeruser_name}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleCustomerGroupEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleCustomerGroupDeleteClick(
                                rowData.customergroup_uniq_id
                              )
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "customer" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.customer_id}</td>
                  <td>{rowData.customer_name}</td>
                  <td>{rowData.customergroup_name}</td>
                  <td>{rowData.customer_user_id}</td>
                  <td>{rowData.password}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleCustomerEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleCustomerDeleteClick(
                                rowData.customer_unique_id
                              )
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}

              {type === "turbine" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.customer_name}</td>
                  <td>{rowData.wtg_no}</td>
                  <td>{rowData.loc_no}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleTurbineEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleTurbineDeleteClick(rowData.turbine_id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "error" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.error_code}</td>
                  <td>{rowData.error_describtion}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleErrorEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleErrorDeleteClick(rowData.error_id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "Gridfault" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.grid_fault_code}</td>
                  <td>{rowData.grid_fault_describtion}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleGridEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleGridDeleteClick(rowData.grid_fault_id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "dailygen" && ( // Rendering when type is not "USER"
                <>
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.wtg_no}</td>
                  <td>{moment(rowData.dg_date).format('DD-MM-YYYY')}</td>
                  <td>{rowData.overtotal_hours}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* <Dropdown.Item onClick={() => handleDailygenViewClick(rowData)}>View</Dropdown.Item> */}
                        <Dropdown.Item
                          onClick={() => handleDailygenEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleDailygenDeleteClick(
                                rowData.daily_generation_id
                              )
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "griddrop" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.grid_drop_code}</td>
                  <td>{rowData.grid_drop_describtion}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleGriddropEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleGriddropDeleteClick(rowData.grid_drop_id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "maintenance" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.maintenance_code}</td>
                  <td>{rowData.maintenance_describtion}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleMaintenanceEditClick(rowData)}
                        >
                          Edit
                        </Dropdown.Item>
                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleMaintenanceDeleteClick(
                                rowData.maintenance_id
                              )
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {type === "pdfupload" && (
                <>
                  {" "}
                  {/* Fragment shorthand */}
                  <td>{indexOfFirstItem + rowIndex + 1}</td>
                  <td>{rowData.turbine_number}</td>
                  <td>{rowData.pdfFile}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle>
                        <Button className="action">
                          <BiDotsVerticalRounded />
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>

                        {loading ? (
                          <center>
                            <Spinner animation="border" variant="dark" />{" "}
                          </center>
                        ) : (
                          <Dropdown.Item
                            onClick={() =>
                              handleGriddropDeleteClick(rowData.grid_drop_id)
                            }
                          >
                            Delete
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </>
              )}
              {/* {type === "customer" && ( // Rendering when type is not "USER"
                            <>
                                <td>{rowData.id}</td>
                                <td>{rowData.name}</td>
                                <td>{rowData.place}</td>
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <Button className='action'><BiDotsVerticalRounded /></Button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleCustomerViewClick(rowData)}>View</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCustomerEditClick(rowData)}>Edit</Dropdown.Item>
                                            {loading ? <center><Spinner animation="border" variant="dark" /> </center> :
                                                <Dropdown.Item onClick={() => handleCustomerDeleteClick(rowData.id)}>Delete</Dropdown.Item>}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </>
                        )} */}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default TableUI;
